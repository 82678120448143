
//people icons

import peopleIconDiego from "./images/pic_people/foto_diego.jpeg"
import peopleIconMauricio from "./images/pic_people/foto_mauri.jpg"
import peopleIconNathan from "./images/pic_people/foto_nathan.jpeg"
import apoiadores_uniftec from "./images/apoiadores/grupo_uniftec_apoiadores.jpeg"

// Social Icon
import linkedin from "./images/icon_footer_linkedin.svg"
import spotify from "./images/icon_footer_spotify.svg"
import instagramIcon from "./images/instagram.svg"
import youtube from "./images/youtube.svg"


export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Tá No Mudo",
  headerTagline: [
    //Line 1 For Header
    "Queremos descomplicar a",
    //Line 2 For Header
    "Tecnologia !"
  ],
  //   Header Paragraph
  headerParagraph:
    "Mais do que um podcast, temos o propósito de trazer conteúdo atual sobre Tecnologia, de uma maneira descontraída e democrática. Confira nossos episódios na sua plataforma preferida.",

  //Contact Email
  contactEmail: "podcasttanomudo@gmail.com",

  // End Header Details -----------------------
  // Work Section ------------------------
  projects: [
    { 
        title: "", //Project Title - Add Your Project Title Here
        para:"",
        url: "https://open.spotify.com/embed/episode/0HG7LKPMuWzBSGlrDmX1rQ?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/6xb9kL0JnACMMpHi3wPrKP?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/0gi5VJ4sEYtF0nSogo2VaR?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/0eDMc3JzvyDGZPmehxg2Fd?utm_source=generator",
    },
    ,
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/0NQKDk01kzWuJDJ97mpCQd?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/6hCTWe8HyQfWMVpb4W8Ft8?utm_source=generator",
    },
    
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url:"https://open.spotify.com/embed/episode/2WVWIlIuFsdJTipfTWiCpU?utm_source=generator",
    },
    
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/2vL04v6JCE69YhlrEuvU4c?utm_source=generator",
    },
    
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/4KSfjcdIj5nUuyIysOvjsG?utm_source=generator",
      
    },
    
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/1XgrnBCJSbTfQYwDjgBXlU?utm_source=generator",
    },   
    { 
      title: "", //Project Title - Add Your Project Title Here 40
      para:"",
      url: "https://open.spotify.com/embed/episode/2jRPoNSIRbbfqA69gPmLul?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here 39
      para:"",
      url: "https://open.spotify.com/embed/episode/5ksjunmJNM4JbB8UAjrOjm?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here 38
      para:"",
      url: "https://open.spotify.com/embed/episode/11wtD4nGCQ7naLDXc8GENj?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here 
      para:"",
      url: "https://open.spotify.com/embed/episode/4BfKCXCyF50YWOuxifF8Tq?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here 
      para:"",
      url: "https://open.spotify.com/embed/episode/3r3eC1ybhqc515yK1W0A4o?utm_source=generator",
    },

    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/6JT8oTMR69XMWguybe5UK2?utm_source=generator",
    },
    
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/3OJ504ZaVYlm230PwD3kl2?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/0Kd5qP54wcrL4brUR8QD48?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/2YlYob9hYmdmdJm3T8Srry?utm_source=generator",
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/5ihw4ItCbcYcG8Wj0fbitt?utm_source=generator" ,
    },
    { 
      title: "", //Project Title - Add Your Project Title Here
      para:"",
      url: "https://open.spotify.com/embed/episode/5WBJQtsXqwBpWd64sLoXKj?utm_source=generator",
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",

      url: "https://open.spotify.com/embed/episode/4CC39LVkvEsmV2UJHZA90k?utm_source=generator",
    },

    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/1WMx2ZIk8Xc26oPH7f2UHL?utm_source=generator"  ,
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "",

      //Project URL - Add Your Project Url Here https://open.spotify.com/episode/4CC39LVkvEsmV2UJHZA90k?si=4dc026e330754880
      url: "https://open.spotify.com/embed/episode/2zJXBZ17nDggdMiiCDzMqc?utm_source=generator",
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/0G2lOh61cPuSpXyiPYiFQY?utm_source=generator"
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "",

      //Project URL - Add Your Project Url Here https://open.spotify.com/episode/4CC39LVkvEsmV2UJHZA90k?si=4dc026e330754880
      url: "https://open.spotify.com/embed/episode/4GLxv8RJrEpBDCcDEzzSJG?utm_source=generator"

    },    
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "",

      //Project URL - Add Your Project Url Here https://open.spotify.com/episode/4CC39LVkvEsmV2UJHZA90k?si=4dc026e330754880
      url: "https://open.spotify.com/embed/episode/02JKWaBnAsdiVGqdMem2DR?utm_source=generator",
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "",

      //Project URL - Add Your Project Url Here https://open.spotify.com/episode/4CC39LVkvEsmV2UJHZA90k?si=4dc026e330754880
      url: 
      "https://open.spotify.com/embed/episode/6IoUxLjxSrGB1pKxj6sues?utm_source=generator",
    },

    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/1hs34nbBSd4nu17mK4VwSr?utm_source=generator",
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/71elbxJQwlcKqzt8kuyrGf?utm_source=generator",
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/532BTzKTmykZBaw6v9ARUb?utm_source=generator",
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/4pXQk2J3NOzIFwNYt76q5f?utm_source=generator"      ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/5qsA3vigrT1lVDKddygj4D?utm_source=generator"     ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/4XhRS48Ab9cc4gDVe9ReBg?utm_source=generator"    ,
      
    },
    {
      title: "16", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/2TPMrJdpkIBQNiYIp6rmRf?utm_source=generator"     ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/21gaHJCTbkzSyqBpicb0xE?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url:"https://open.spotify.com/embed/episode/7jEKZwZtSdCRGZZIHnyBQg?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url:"https://open.spotify.com/embed/episode/127oKyV8r3ILzNPtBzqbbu?utm_source=generator"    ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/2ILm2lmHwEFasprut13vUc?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/51p1XVwDY5YBEMOsSt7BAU?utm_source=generator"     ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/44dxmlFJ8NKceToDMrT5ml?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url:"https://open.spotify.com/embed/episode/2qvHXXU6ZYRbCocRniwTIU?utm_source=generator"    ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url:"https://open.spotify.com/embed/episode/6a3QbdiNbXCsu0UeiNJfih?utm_source=generator"    ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/0S4dQFDsyD6NJXF1CCvpBA?utm_source=generator"    ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/23fkcssMhDZPQ03lSoW4Dy?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/3G0z9dl6lzOGDBnyx3qXNH?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/0osjJIMglKUG87t1pv4G5c?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/235ljM1C1ulMRfGsInyGWa?utm_source=generator"   ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/2E47TEwmZHhMkIUS4rGcN8?utm_source=generator"    ,
      
    },
    {
      title: "", //Project Title - Add Your Project Title Here
      para:"", // Add Your Service Type Here
      imageSrc: "",
      url: "https://open.spotify.com/embed/episode/38GYz9FkFVObmO0EIdJjy1?utm_source=generator"    ,
      
    },
    

  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "-Bora fazer um podcast ? - Bora descomplicar as coisas ? - Acho que podemos compartilhar muita coisa com o mundo! E o nome, que tal Tá No Mudo ? - Bah que massa, bora!",
  aboutParaTwo:
    "E foi assim que nasceu o Tá no Mudo! Podcast (mais que isso) que aborda conteúdos do dia a dia, de maneira simples e descontraída. Somos curiosos e gostamos de compartilhar todo este conhecimento, trazendo pessoas especialistas nos assuntos discutidos ou quando nos mesmos assumimos a frente e elaboramos os episodios trazendo nosso ponto de vista! Enfim, nosso propósito é descomplicar a tecnologia e os negocios. #TaNoMudo #TaNoMudoCast",
  aboutImage:
    "https://d1fdloi71mui9q.cloudfront.net/crRH9P3Sahac2fUKWpJA_8EJoZCf2m4xTZQ4x",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: peopleIconDiego,
      img_linkedin: linkedin,
      url_linkedin: "vieiradiegom/",
      title: "Diego Moreira Vieira",
      para:
        "IT Engineering Coordinator | Digital Products | Open Finance @ Itau",
    },
    {
      img: peopleIconMauricio,
      img_linkedin: linkedin,
      url_linkedin: "mauriciobrandalise/",
      title: "Mauricio Modesto Toscan Brandalise",
      para:
        "Data and Digital Automation Coordinator | Data Products | Leadership @ Randon Companies",
    },
    {
      img: peopleIconNathan,
      img_linkedin: linkedin,
      url_linkedin: "nathansoares/",
      title: "Nathan Idiarte Soares",
      para:
        "Digital Transformation Coordinator | Fras-le S/A | Randon @ Randon Companies",    }
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Apoiadores",
  promotionPara:
    "O Tá No Mudo conta com a parceria de:",
  promotionImg: apoiadores_uniftec,

  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Vamos descomplicar a tecnologia juntos ?", 
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: linkedin,
      url: "https://www.linkedin.com/company/t%C3%A1-no-mudo",
    },
    {
      img: spotify,
      url: "https://open.spotify.com/show/45S2UtxcRNrYrhknhYY4BV",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/tanomudocast",
    },
    {
      img: youtube,
      url: "https://www.youtube.com/channel/UC8rfYmSZfL2haN5aLpCeotA/featured",
    }
  ],

  // End Contact Section ---------------
}

